// 
// dd-list.scss
//

.dd-list {
  .dd-item {
      .dd-handle {
          border: none;
          padding: 8px 16px;
          height: auto;
          font-weight: 600;
          border-radius: 3px;
          background: $dropdown-link-hover-bg;
          color: $menu-item;

          &:hover {
             cursor: grab;
          }
      }
      button {
          height: 36px;
          font-size: 17px;
          margin: 0;
          color: $gray-600;
          width: 36px;
      }
  }
  .dd3-item {
      margin: 5px 0;

      .dd-item button {
          width: 36px;
          height: 36px;
      }
  }
  .dd3-handle {
      margin: 0;
      height: 36px !important;
      float: left;
  }
  .dd3-content {
      height: auto;
      border: none;
      padding: 8px 16px 8px 46px;
      background: $dropdown-link-hover-bg;
      color: $menu-item;
      font-weight: 600;
      &:hover {
        cursor: grab;
      }
  }
  .dd3-handle:before {
      content: "\F035C";
      font-family: "Material Design Icons";
      color: $gray-500;
  }
}

.dd-empty,
.dd-placeholder {
  background: rgba($gray-400,0.2);
}

.dd-dragel {
  .dd-handle {
      box-shadow: $box-shadow;
  }
}