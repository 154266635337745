//
// toastr.scss
//

.jq-toast-single {
  padding: 15px;
  font-family: $font-family-base;
  background-color: $primary;
  font-size: 13px;
  line-height: 22px;
  h2 {
      font-family: $font-family-base;
  }
  a {
      font-size: $font-size-base;
      &:hover {
          color: $white;
      }
  }
}

.jq-has-icon {
  padding: 10px 10px 10px 50px;
}

.close-jq-toast-single {
  @include position(absolute, -12px, -12px, null, null);
  font-size: 1.5rem;
  cursor: pointer;
  height: 32px;
  width: 32px;
  background-color: $white;
  border-radius: 50%;
  text-align: center;
  line-height: 32px;
  color: $dark;
}

.jq-toast-loader {
  height: 3px;
  top: 0;
  border-radius: 0;
}

@each $color,$value in $theme-colors {
  .jq-icon-#{$color} {
      background-color: #{$value};
      color: $white;
      border-color: #{$value};
      .close-jq-toast-single{
        color: $value;
      }
  }
}

// For error
.jq-icon-error {
  background-color: $danger;
  color: $white;
  border-color: $danger;
  .close-jq-toast-single{
    color: $danger;
  }
}