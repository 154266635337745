// 
// tablesaw.scss
//

.tablesaw {
  thead {
      background: $table-head-bg;
      background-image: none;
      border: none;
      th {
          text-shadow: none;
      }
      tr:first-child th {
          border: none;
          font-family: $font-family-secondary;
      }
  }
  td {
      border-top: 1px solid $table-head-bg !important;
  }
}

.tablesaw td,
.tablesaw tbody th {
  font-size: inherit;
  line-height: inherit;
  padding: 10px !important;
}

.tablesaw-stack tbody tr,
.tablesaw tbody tr {
  border-bottom: none;
}

.tablesaw-bar .btn-select.btn-small:after,
.tablesaw-bar .btn-select.btn-micro:after {
  font-size: 8px;
  padding-right: 10px;
}

.tablesaw-swipe .tablesaw-cell-persist {
  box-shadow: none;
  border-color: $table-head-bg;
}

.tablesaw-enhanced .tablesaw-bar .btn {
  text-shadow: none;
  background-image: none;
  text-transform: none;
  border: 1px solid $gray-300;
  padding: 3px 10px;
  color: $dark;

  &:after {
      display: none;
  }
}

.tablesaw-enhanced .tablesaw-bar .btn.btn-select {
  &:hover {
      background: $white;
  }
}

.tablesaw-enhanced .tablesaw-bar .btn:hover,
.tablesaw-enhanced .tablesaw-bar .btn:focus,
.tablesaw-enhanced .tablesaw-bar .btn:active {
  color: $primary !important;
  background-color: $table-hover-bg;
  outline: none !important;
  box-shadow: none !important;
  background-image: none;
}

.tablesaw-columntoggle-popup {
  border-color: $dropdown-border-color;
  box-shadow: $dropdown-box-shadow;
  .tablesaw-btn-group{
    & > label{
      display: flex;
      align-items: center;
      input{
        margin-right: 0.5rem;
      }
    }
  }
  .btn-group {
      display: block;
  }
}

.tablesaw-swipe .tablesaw-swipe-cellpersist {
  border-right: 2px solid $table-head-bg;
}

.tablesaw-sortable-btn {
  cursor: pointer;
}

.tablesaw-swipe-cellpersist {
  width: auto!important;
}

.tablesaw-bar-section label {
  color: inherit;
}

.tablesaw-bar-section {
  label{
    text-transform: none;
  }
}
