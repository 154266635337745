//
// bootstrap-select.scss
//

.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
  width: 100% !important;
}

.bootstrap-select {
  .dropdown-toggle {
      &:after {
          content: "\F0140";
          display: inline-block;
          font-family: "Material Design Icons";
          padding-left: 10px;
      }

      &:focus {
          outline: none !important;
          outline-offset: 0;
      }
  }

  a {
      outline: none !important;
  }

  .inner {
      overflow-y: inherit !important;
  }

  > .dropdown-toggle {
      .bs-placeholder {
          color: $input-placeholder-color;

          &:active,
          &:focus,
          &:hover {
              color: $input-placeholder-color;
          }
      }
      
  }
}