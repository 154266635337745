
//
// alertify.scss
//


.alertify {
    .ajs-dialog{
        box-shadow: $box-shadow;
    }
    .ajs-header{
        font-size: 1.25rem;
        font-weight: 600;
    }
    .ajs-footer {
        .ajs-buttons {
            .ajs-button{
                &.ajs-ok{
                    color: $white;
                    background: $primary;
                    @include border(null, 1px, solid, $primary);
                    border-radius: $border-radius;
                }
                &.ajs-cancel{
                    color: $white;
                    background: $secondary;
                    @include border(null, 1px, solid, $secondary);
                    border-radius: $border-radius;
                }
            }
        }
    }
}

.alertify-notifier {
    .ajs-message{
        background-color: $primary;
        border-color: $primary;
        color: $white;
        text-shadow: none !important;
        &.ajs-success{
            background-color: $success;
            border-color: $success;
        }
        &.ajs-error{
            background-color: $danger;
            border-color: $danger;
        }
        &.ajs-warning{
            background-color: $warning;
            border-color: $warning;
        }
    }
}
