//
// Select 2
//

.modal-backdrop {
    & ~ .select2-container {
        z-index: 1055;
    }
}

.select2-container {
  width: 100%!important;
  
  .select2-selection--single {
      border: $input-border-width solid $input-border-color;
      height: calc(#{$input-line-height * 1em} + #{$input-btn-padding-x * 2} + #{$input-height-border});
      line-height: calc(#{$input-line-height * 1em} + #{$input-btn-padding-x * 2} + #{$input-height-border});
      background-color: $input-bg;
      outline: none;
      border-radius: $input-border-radius;
      
      .select2-selection__rendered {
          padding-left: 12px;
          color: $input-color;
          line-height: calc(#{$input-line-height * 1em} + #{$input-btn-padding-x * 2} + #{$input-height-border});
      }
      .select2-selection__arrow {
          width: 34px;
          right: 3px;
          top: 0;
          bottom: 0;
          margin-top: auto;
          margin-bottom: auto;
          b {
              border-color: $gray-500 transparent transparent transparent;
              border-width: 6px 6px 0 6px;
          }
      }
  }
}

.select2-container--open {
    .select2-selection--single {
        border-color: $primary;
    }
}

.select2-container--open {
  .select2-selection--single {
      .select2-selection__arrow {
          b {
              border-color: transparent transparent $gray-500 transparent !important;
              border-width: 0 6px 6px 6px !important;
          }
      }
  }
}

.select2-results__option {
  padding: 6px 12px;
}

.select2-dropdown {
  border: $dropdown-border-width solid $dropdown-border-color;
  box-shadow: $box-shadow;
  background-color: $dropdown-bg;
}

.select2-container--default {
  .select2-search--dropdown {
      padding: 10px;
      background-color: lighten($dropdown-bg,2.5%);
      .select2-search__field {
          outline: none;
          border: 1px solid $input-border-color;
          background-color: $input-bg;
          color: $input-color;
      }
  }
  .select2-results__option--highlighted[aria-selected] {
      background-color: $primary;
  }
  .select2-results__option{
        max-height: 250px;
      &:hover {
          background-color: $primary;
          color: $white;
      }
  }
  .select2-results__option[aria-selected=true] {
    background-color: $primary;
    color: $white;
  }
}

.select2-container {
  .select2-selection--multiple {
      min-height: $input-height;
      border: $input-border-width solid $input-border-color !important;
      background-color: $input-bg;
      height: calc(#{$input-line-height * 1em} + #{$input-btn-padding-x * 2} + #{$input-height-border} + 1);
      padding-top: 6px;
      padding-bottom: 6px;

      .select2-selection__rendered {
          padding: 2px 10px;
      }
      .select2-search__field {
          border: 0;
          color: $input-color;
      }
      .select2-selection__choice {
          background-color: rgba($primary, 0.3);
          border: none;
          color: $primary;
          border-radius: 3px;
          padding: 0 7px;
          margin-top: 6px;
          font-weight: bold;
      }
      .select2-selection__choice__remove {
          color: $primary;
          margin-right: 5px;
          font-weight: bold;
          &:hover {
              color: $primary;
          }
      }
  }
}