/* 

====== Zoom effect ======

*/

@mixin mfpscale ($op:1, $trans: null) {
  @include transform($trans);
  opacity: $op;
}

.mfp-zoom-in {
  .mfp-with-anim {
    @include mfpscale(0, scale(0.8));
    @include transition(all 0.2s ease-in-out);
  }

  &.mfp-bg {
    opacity: 0;
    @include transition(all 0.3s ease-out);
  }

  &.mfp-ready {
    .mfp-with-anim {
      @include mfpscale(1, scale(1));
    }

    &.mfp-bg {
      opacity: 0.8;
    }
  }

  &.mfp-removing {
    .mfp-with-anim {
      @include mfpscale(0, scale(0.8));
    }

    &.mfp-bg {
      opacity: 0;
    }
  }
}

/* 

====== Newspaper effect ======

*/
.mfp-newspaper {
  .mfp-with-anim {
    @include mfpscale(0, scale(0) rotate(500deg));
    @include transition(all 0.5s ease-out);
  }

  &.mfp-bg {
    opacity: 0;
    @include transition(all 0.5s);
  }

  &.mfp-ready {
    .mfp-with-anim {
      @include mfpscale(1, scale(1) rotate(0));
    }
  }

  &.mfp-ready.mfp-bg {
    opacity: 0.8;
  }

  &.mfp-removing {
    .mfp-with-anim {
      @include mfpscale(0, scale(0) rotate(500deg));
    }
  }

  &.mfp-removing.mfp-bg {
    opacity: 0;
  }
}

/* 

====== Move-horizontal effect ======

*/
.mfp-move-horizontal {
  .mfp-with-anim {
    @include mfpscale(0, translateX(-50px));
    @include transition(all 0.3s);
  }
  &.mfp-bg {
    opacity: 0;
    @include transition(all 0.3s);
  }
  &.mfp-ready {
    .mfp-with-anim {
      @include mfpscale(1, translateX(0));
    }
    &.mfp-bg {
      opacity: 0.8;
    }
  }
  &.mfp-removing {
    .mfp-with-anim {
      @include mfpscale(0, translateX(50px));
    }
  }
  &.mfp-removing.mfp-bg {
    opacity: 0;
  }
}

/* 

====== Move-from-top effect ======

*/

.mfp-move-from-top {
  .mfp-content {
    vertical-align: top;
  }
  .mfp-with-anim {
    @include mfpscale(0, translateY(-100px));
    @include transition(all 0.3s);
  }
  &.mfp-bg {
    opacity: 0;
    @include transition(all 0.3s);
  }
  &.mfp-ready .mfp-with-anim {
    @include mfpscale(1, translateY(0));
  }
  &.mfp-ready.mfp-bg {
    opacity: 0.8;
  }
  &.mfp-removing .mfp-with-anim {
    @include mfpscale(0, translateY(-50px));
  }
  &.mfp-removing.mfp-bg {
    opacity: 0;
  }
}
/* 

====== 3d unfold ======

*/

.mfp-3d-unfold {
  .mfp-content {
    perspective: 2000px;
  }
  .mfp-with-anim {
    @include mfpscale(0, rotateY(-60deg));
    @include transition(all 0.3s ease-in-out);
    transform-style: preserve-3d;
  }
  &.mfp-bg {
    opacity: 0;
    @include transition(all 0.5s);
  }
  &.mfp-ready {
    .mfp-with-anim {
      @include mfpscale(1, rotateY(0deg));
    }
    &.mfp-bg {
      opacity: 0.8;
    }
  }
  &.mfp-removing {
    .mfp-with-anim {
      @include mfpscale(0, rotateY(60deg));
    }
  }
  &.mfp-removing.mfp-bg {
    opacity: 0;
  }
}

/* 

====== Zoom-out effect ======

*/

.mfp-zoom-out {
  .mfp-with-anim {
    @include mfpscale(0, scale(1.3));
    @include transition(all 0.3s ease-in-out);
  }
  &.mfp-bg {
    opacity: 0;
    @include transition(all 0.3s ease-in-out);
  }
  &.mfp-ready .mfp-with-anim {
    @include mfpscale(1, scale(1));
  }
  &.mfp-ready.mfp-bg {
    opacity: 0.8;
  }
  &.mfp-removing .mfp-with-anim {
    @include mfpscale(0, scale(1.3));
  }
  &.mfp-removing.mfp-bg {
    opacity: 0;
  }
}
