// 
// components-demo.scss
//

// Demo Only
.button-list {
  margin-right: -0.5rem;
  &:last-child {  
    margin-bottom: -0.75rem;
  }
  .btn {
      margin-right: 0.5rem;
      margin-bottom: 0.75rem;
  }
}

// Grid
.grid-structure {
  .grid-container {
      background-color: $table-accent-bg;
      margin-bottom: 10px;
      font-size: 0.8rem;
      font-weight: $font-weight-semibold;
      padding: 10px 20px;
  }
}

// Icon demo ( Demo only )
.icons-list-demo {
  div.col-sm-6 {
      cursor: pointer;
      line-height: 45px;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: block;
      overflow: hidden;
      p {
          margin-bottom: 0;
          line-height: inherit;
      }
  }
  i {
      text-align: center;
      vertical-align: middle;
      font-size: 1.25rem;
      width: 50px;
      height: 50px;
      line-height: 50px;
      margin-right: 12px;
      color: $text-muted;
      border-radius: 3px;
      display: inline-block;
      @include transition(all 0.3s);
  }
  .col-md-4 {
      border-radius: 3px;
      background-clip: padding-box;
      margin-bottom: 10px;
      &:hover,
      &:hover i {
          color: $primary;
      }
  }

  .icon-item{
      svg{
          margin-right: 10px;
      }
      
      span{
          line-height: 30px;
          display: inline-block;
          vertical-align: middle;
      }
  }
}


// Scrollspy

.scrollspy-example {
  position: relative;
  height: 200px;
  margin-top: .5rem;
  overflow: auto;
}