.counter-number{
  @include media-breakpoint-up(md) {
    display: flex;
  }
  .coming-box{
    padding: 1rem;
    background: $white;
    font-size: 2.25rem;
    font-weight: 600;
    text-align: center;
    border-radius: 5px;
    span{
      display: block;
      font-size: 1.15rem;
    }
  }
}