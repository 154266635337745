//CKeditor 4 css

.cke_toolbox {
  display: flex;
  flex-wrap: wrap;
}
.cke.cke_reset, .cke_bottom{
  border-color: $border-color !important;
}
.cke_top{
  background: $light !important;
  border-color: $border-color !important;
}

//CK Classic Editor css
.ck.ck-toolbar {
  border-color: $border-color !important;
  background: $light !important;
  .ck.ck-toolbar__separator{
    background-color: $gray-300 !important;
  }
}

.ck-editor__editable_inline {
  min-height: 240px;

  &.ck-focused {
      box-shadow: none  !important;
      border-color: $border-color !important;
  }
  
  &.ck-blurred {
      box-shadow: none  !important;
      border-color: $border-color !important;
  }
} 

.ck.ck-reset_all,
.ck.ck-reset_all * {
  color: $body-color !important;
}

.ck-editor__editable {
  background: transparent !important;
}
