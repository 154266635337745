.horizontal-navbar {
  .left-bar {
    .navbar-toggle {
      display: none;
      flex-direction: column;
      padding-left: 15px;
      @include media-breakpoint-down(md) {
        display: flex;
      }
      span {
        width: 24px;
        margin-bottom: 6px;
        height: 2px;
        background: $white;
        display: block;
        -webkit-transform-origin: 4px 0px;
        transform-origin: 4px 0px;
        transition: background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease,
          -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
          opacity 0.55s ease;
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
          opacity 0.55s ease, -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);

        &:last-child {
          margin-bottom: 0;
        }
      }

      &:not(.collapsed) {
        span {
          opacity: 1;
          @include transform(rotate(45deg) translate(4px, 0));

          &:last-child {
            opacity: 0;
          }

          &:nth-last-child(2) {
            @include transform(rotate(-45deg) translate(-3px, 5px));
          }
        }
      }
    }
  }


  .navbar-header,
  .container-fluid {
    @include media-breakpoint-up(xl) {
      max-width: 85%;
      margin: 0 auto;
    }
  }

  .navbar {
    padding: 0;
  }

  .navbar-header {
    padding: 0 15px;
  }

  .logo-light {
    display: none;
  }

  .navbar-brand-box {
    width: auto;
    background: none;
    padding: 0;
  }

  .logo-dark {
    display: block !important;
  }

  .main-content {
    margin-left: 0;
    margin-top: 122px;
    padding: 0;
  }

  .page-title-box {
    background-color: rgba($primary, 0.11);

    h4 {
      color: $dark;
    }

    .breadcrumb {
      .breadcrumb-item{
        color: $gray-700;
      }
      &.active {
        color: $text-muted;
      }
    }
  }

  .footer {
    margin-left: 0;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}


.horizontal-topnav {
  background: $white;
  @include position(fixed, 70px, 0, null, 0, 12);

  .navbar-nav {
    .nav-link {
      padding: 1rem;
      color: $menu-item;
      display: flex;
      align-items: center;

      &[aria-expanded=true] {
        color: $menu-item-active;
      }
    }

    .dropdown:first-of-type {
      @include media-breakpoint-up(lg) {
        .nav-link {
          padding-left: 0;
        }
      }
    }

    .dropdown-toggle>i {
      font-size: 1rem;
    }

    .dropdown-toggle>.bx-chevron-right {
      margin-left: auto;
    }

    .dropdown:last-of-type {
      .nav-link {
        padding-right: 0;
      }
    }

    .dropdown-item {

      &:hover,
      &:focus {
        color: $menu-item-active;
      }
    }

    .dropdown-menu {
      .dropdown-menu {
        position: absolute;
        left: 100%;
        top: 0;
        border-radius: 0 0.25rem 0.25rem 0;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .horizontal-topnav {
    .dropdown {
      &:hover {
        .nav-link {
          color: $menu-item-active;
        }

        &>.dropdown-menu {
          display: block;
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .horizontal-navbar {
    
    .main-content {
      margin-top: 70px;
    }

  }
  .horizontal-topnav {
    max-height: 360px;
    overflow-y: auto;
    padding: 0;


    .navbar-nav {
      .nav-link {
        .bx-chevron-down {
          margin-left: auto;
        }
      }
      .dropdown-menu {
        position: static;
        padding-left: 15px;
        box-shadow: none;

        .dropdown-item:hover,
        .dropdown-item:focus {
          background: none;
        }

        .dropdown-menu {
          position: static;
        }
      }
    }
  }
}