@mixin center-block-horizontal($width){
  width: $width;
  margin: 0 auto;
}

.project-list {
  tbody {
    td:last-child:not(.child){
      text-align: center;
    }
  }
}

.add-project{
  border: none;
  .ck-editor__editable_inline{
    height: 100px;
    overflow: auto;
    min-height: 100px;
    max-height: 100px;
  }
}

.project-review-list{
  margin-bottom: 1.5rem;
  .card-title{
    margin-bottom: 1.5rem;
  }
  h5 {
    padding-bottom: 10px;
    @include border(bottom, 1px, solid, $border-color);
  }
  h6, .html{
    margin-bottom: 0.75rem;
  }
  p{
    margin-bottom: 0;
  }
  &:last-child{
    border-bottom: none;
  }
}