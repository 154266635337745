.inbox-leftbar {
  .mail-control {
    a {
      padding: 0.5rem 0.75rem;
      display: flex;
      border-radius: 0.3rem;
      align-items: center;
      color: $gray-700;

      span {
        margin-left: auto;
      }

      &:hover {
        color: $black;
      }
    }
  }

  .chat-list {
    margin: 0 -1.25rem;
    li {
      a {
        padding: 1rem 1.25rem;
        &:hover {
          background-color: transparent;
        }
      }

      .contact-status {
        margin-left: 0;
        left: 0;
      }
    }
  }
}

.inbox-rightbar{
  height: calc(100% - 24px);
}

.email-message-list {
  padding-left: 0;
  margin-bottom: 0.5rem;
  li {
    display: flex;
    align-items: center;
    overflow: hidden;
    line-height: 50px;
    height: 50px;
    margin-bottom: 1px;
    transition-duration: .3s;
    background: $card-bg;
    position: relative;
    box-shadow: $box-shadow-sm;

    a {
      color: $gray-700;
      &:hover {
        color: darken($gray-700, 5);
      }
    }

    &:last-child{
      box-shadow: none;
    }
    &.unread{
      background-color: lighten($light, 3);
    }
    &.marked{
      .star-toggle{
        color: $warning;
      }
    }
    &:hover {
      box-shadow: $box-shadow;
      z-index: 1;
      transition-duration: .05s;
      .action-list{
        @include transform(translateX(0));
      }
    }

    .col-mail-1 {
      max-width: 320px;
      flex: 1;
      display: flex;
      align-items: center;
    }

    .col-mail-2 {
      flex: 1;
      display: flex;
      align-items: center;
      overflow: hidden;

      .date {
        min-width: 100px;
        padding-left: 0.5rem;
        padding-right: 1rem;
        text-align: right;
        color: rgba($gray-600, 0.9);
      }

      .subject {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: calc(100% - 100px);
        display: inline-block;
      }
    }
    .action-list {
      padding: 0 1rem;
      position: absolute;
      right: 0;
      z-index: 2;
      top: 0;
      background: $white;
      display: flex;
      @include transform(translateX(100%));
      @include transition(all 0.3s);
    }
  }

  .checkbox-wrapper-mail {
    margin: 0 10px 0 1rem;
    max-width: 20px;
    width: 100%;
    display: flex;
  }

  .star-toggle {
    color: $gray-500;
    margin-right: 10px;
  }
}

.checkbox-wrapper-mail { 
  .custom-control-label {
    &:before {
      // background: darken($custom-checkbox-bg, 8);
    }
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    // background: darken($custom-checkbox-bg, 8);
  }
}

// .checkbox-wrapper-mail {
//   cursor: pointer;
//   height: 20px;
//   width: 20px;
//   position: relative;
//   display: inline-block;
//   box-shadow: inset 0 0 0 2px $gray-400;
//   border-radius: 3px;

//   input {
//     opacity: 0;
//     cursor: pointer;
//   }

//   input:checked~label {
//     opacity: 1;
//   }

//   label {
//     position: absolute;
//     top: 3px;
//     left: 3px;
//     right: 3px;
//     bottom: 3px;
//     cursor: pointer;
//     background: $gray-600;
//     opacity: 0;
//     margin-bottom: 0 !important;
//     transition-duration: .05s;
//   }

//   label:active {
//     background: #87949b;
//   }
// }

.attachment {
  position: relative;
  .actions {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    bottom: 0;
    right: 0;
    background: rgba($black, 0.8);
    padding: 10px;
    color: $white;
    flex-wrap: wrap;
    opacity: 0;
    visibility: hidden;
    @include transition(all 0.3s);
    h6{
      color: $white;
      span{
        width: 100%;
        display: block;
      }
      .title{
        margin-bottom: 0.25rem;
      }
    }
    .download-btn{
      background: $gray-700;
      color: $white;    
      width: 1.5rem;
      height: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.25rem;
    }
  }
  &:hover{
    .actions{
      opacity: 1;
      visibility: visible;
    }
  }
}