.fs-11{
  font-size: px-rem-converter(11);
}

.fs-12{
  font-size: px-rem-converter(12);
}

.fs-13{
  font-size: px-rem-converter(13);
}

.fs-14{
  font-size: px-rem-converter(14);
}

.fs-15{
  font-size: px-rem-converter(15);
}

.fs-16, .font-default{
  font-size: px-rem-converter(16);
}

.fs-xl{
  font-size: 3rem;
}

.fs-lg{
  font-size: 2rem;
}

.fs-md{
  font-size: 1.5rem;
}

.fs-sm{
  font-size: 1.25rem;
}

.fs-xs{
  font-size: 1rem;
}

.fw-semibold{
  font-weight: 600 !important;
}

@each $class, $value in $max-heights {
  .ah-#{$class} {
    height: $value;
  }
}