.footer{
  padding: 15px 12px;
  display: flex;
  background: $white;
  margin-left: $sidebar-width;
  flex-wrap: wrap;
  overflow: hidden;
}

.compact-side-menu {
  .footer{
    margin-left: $sidebar-width-sm;
  }
}
.left-side-menu-condensed {
  .footer{
      margin-left: $sidebar-collapsed-width;;
  }
}